import React from 'react';
import Navbar from './global-components/navbar';
import Slider from './section-components/slider';
import Features from './section-components/features';
import PricingTable from './section-components/pricing-table';
import Video from './section-components/video';
import Screenshot from './section-components/screenshot';
import Subscribe from './section-components/subscribe';
import Footer from './global-components/footer';

const Home = () => {
    return <div>
        <Navbar />
        <Slider />
        <Video />
        <Features />
        <Screenshot />
        <PricingTable />
        <Subscribe />
        <Footer />
    </div>
}

export default Home

