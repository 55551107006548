import React from 'react';

import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import Terms from './section-components/terms';

const TermsAndConditions = () => {
  return (
    <div >
        <Navbar />
        <PageHeader headertitle="Termini e Condizioni" />
        <Terms />
        <Footer />
    </div>
  );
};

export default TermsAndConditions;