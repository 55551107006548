import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Pricing from './components/Pricing'; 
import Faq from './components/Faq';
import TermsAndConditions from './components/TermsAndConditions';

class Root extends Component {
    render() {
        return(
            <Router>
                <div>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    </Routes>
                </div>
            </Router>
        )
    }
}

export default Root;

const container = document.getElementById('trucksout');
const root = createRoot(container);
root.render(<Root />);