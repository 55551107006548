import React, { useState } from 'react';
import sectiondata from '../../data/sections.json';

const Subscribe = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSubmissionStatus('');

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            await response.json();
            if (response.ok) {
                setSubmissionStatus('Iscrizione avvenuta con successo!');
            } else {
                setSubmissionStatus('Iscrizione fallita!');
            }
        } catch (error) {
            setSubmissionStatus('Si è verificato un errore. Per favore riprova.');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div className="newsletter-area mg-top-110">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-10 text-center">
                            <div className="section-title">
                                <h2 className="title">{sectiondata.subscribe.sectiontitle} <span>{sectiondata.subscribe.sectiontitle_color}</span></h2>
                                <p>{sectiondata.subscribe.short_description}</p>
                            </div>
                            <div className="newsletter-subcribe">
                                <form id="news-subcribeform" className="subcribe-form" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="La tua email..."
                                            name="mail"
                                            value={email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <button type="submit" className="btn-green subcribe-submit" disabled={isLoading}>
                                            {isLoading ? 'Invio in corso...' : 'Iscriviti'}
                                        </button>
                                    </div>
                                    {submissionStatus && (
                                        <div className={`submission-status ${submissionStatus.includes('successo') ? 'success' : 'error'}`}>
                                            {submissionStatus}
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Subscribe;