import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Navbar extends Component {

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imgattr = 'logo'
    return (
      <div>
        <nav className="navbar navbar-area navbar-expand-lg nav-style-01">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <div className="logo-wrapper mobile-logo">
                <Link to="/" className="logo">
                  <img src={publicUrl + 'assets/img/logo.png'} alt={imgattr} />
                </Link>
              </div>
              <div className="nav-right-content">
                <Link to="https://dashboard.trucksout.app/register" className=" btn-rounded text-green btn-small "> <i className="fa fa-user fa-2x" /> </Link>
                <Link to="https://dashboard.trucksout.app/login" className=" btn-rounded text-green btn-small "> <i className="fa fa-sign-in fa-2x" /> </Link>
              </div>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#Trucksout_main_menu"
                aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggle-icon">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="Trucksout_main_menu">
              <div className="logo-wrapper desktop-logo">
                <Link to="/" className="logo">
                  <img src={publicUrl + 'assets/img/logo.png'} alt={imgattr} />
                </Link>
              </div>
              <ul className="navbar-nav">
                <li>
                  <Link to="/about">Chi Siamo</Link>
                </li>
                <li>
                  <Link to="/pricing">Prezzo</Link>
                </li>
                <li>
                  <Link to="/faq">FAQs</Link>
                </li>
                <li>
                  <Link to="/contact">Contattaci</Link>
                </li>
              </ul>
            </div>
            <div className="nav-right-content">
              <ul>
                <li>
                  <a href={`${process.env.REACT_APP_DASHBOARD_URL}/register`} className="btn btn-green btn-rounded text-white">
                    Register
                  </a>
                </li>
                <li>
                  <a href={`${process.env.REACT_APP_DASHBOARD_URL}/login`} className="btn btn-green btn-rounded text-white">
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="body-overlay" id="body-overlay"></div>
        <div className="search-popup" id="search-popup">
          <form action="index.html" className="search-form">
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Search....." />
            </div>
            <button type="submit" className="submit-btn"><i className="fa fa-search"></i></button>
          </form>
        </div>
      </div>
    )
  }
}


export default Navbar