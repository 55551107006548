import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class Features extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        return (
            <div>
                <div className="sba-featute-area">
                    <div className="container">
                        <div className="row custom-gutters-16 justify-content-center">
                            <div className="col-xl-4 col-lg-9">
                                <div className="section-title style-two text-xl-left text-center">
                                    <h2 className="title">
                                        {parse(sectiondata.features.sectiontitle)} <span>{sectiondata.features.sectiontitle_color}</span>
                                    </h2>
                                    <p>{parse(sectiondata.features.short_description_1)}</p>
                                    {parse(sectiondata.features.short_description_list)}
                                    <p>{parse(sectiondata.features.short_description_2)}</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-sm-6">
                                {sectiondata.features.featurescontent1.map((feature, index) => (
                                    <div className={`single-feature ${index % 2 === 0 ? 'single-feature-left' : 'single-feature-right'}`} key={index}>
                                        <div className="single-feature">
                                            <div className={`media ${index % 2 === 0 ? '' : 'border-radius-' + (index + 1)}`}>
                                                <img className="media-left" src={publicUrl + feature.icon} alt="feature" />
                                                <div className="media-body">
                                                    <h6>{feature.title}</h6>
                                                    <p>{feature.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-xl-4 col-sm-6 mg-top-80">
                                {sectiondata.features.featurescontent2.map((feature, index) => (
                                    <div className={`single-feature ${index % 2 === 0 ? 'single-feature-left' : 'single-feature-right'}`} key={index}>
                                        <div className="single-feature">
                                            <div className={`media ${index % 2 === 0 ? '' : 'border-radius-' + (index + 1)}`}>
                                                <img className="media-left" src={publicUrl + feature.icon} alt="feature" />
                                                <div className="media-body">
                                                    <h6>{feature.title}</h6>
                                                    <p>{feature.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Features;