import React, { Component } from 'react';
import './styles/Terms.css';

class Terms extends Component {
    render() {
        return <div className="terms-container">
                <div className="terms-content">
                    <h6>Efficace dal: Ottobre 2024</h6>
                    <p>Questi Termini e Condizioni costituiscono un accordo legalmente vincolante tra te e TruckOut. (insieme alle sue affiliate, "TruckOut", "noi", "nostro" o "ci") che regola l'uso dei nostri prodotti, servizi e sito web (il “Sito” e, insieme a quanto sopra, i “Servizi”).
</p>

<p>RICONOSCI E ACCETTI CHE, CLICCANDO SUL PULSANTE “ACCETTO” O SIMILE, REGISTRANDOTI PER UN ACCOUNT O ACCEDENDO O UTILIZZANDO I SERVIZI, STAI INDICANDO CHE HAI LETTO, COMPRESO E ACCETTATO DI ESSERE VINCOLATO DA QUESTI TERMINI E CONDIZIONI, INDIPENDENTEMENTE DAL FATTO CHE TU TI SIA REGISTRATO AL SITO O MENO. SE NON ACCETTI QUESTI TERMINI E CONDIZIONI, NON HAI IL DIRITTO DI ACCEDERE O UTILIZZARE I SERVIZI. 
</p>

<p>Questi Termini e Condizioni sono efficaci a partire dalla data in cui clicchi per la prima volta su “Accetto” (o su un pulsante o casella simile) o utilizzi o accedi ai Servizi, a seconda di quale evento si verifichi per primo. Se accetti questi Termini e Condizioni per conto del tuo datore di lavoro o di un'altra entità legale, dichiari e garantisci che (i) hai la piena autorità legale per vincolare il tuo datore di lavoro o tale entità a questi Termini e Condizioni; (ii) hai letto e compreso questi Termini e Condizioni; e (iii) accetti questi Termini e Conidizoni per conto della parte che rappresenti. In tal caso, i termini “tu” e “tuo” si riferiranno e si applicheranno al tuo datore di lavoro o a tale altra entità legale.
</p>
<p>Qualsiasi dato personale che ci fornisci o che raccogliamo su di te è disciplinato dalla nostra Politica sulla Privacy (“Privacy Policy”), disponibile su https://www.iubenda.com/privacy-policy/28423907. Riconosci che utilizzando i Servizi, hai preso visione della Privacy Policy. La Privacy Policy è incorporata per riferimento nei presenti Termini e Condizione e insieme formano e sono qui indicati come “Accordo”.
</p>
<p>SI PREGA DI NOTARE: QUESTO ACCORDO DISCIPLINA COME POSSONO ESSERE RISOLTE LE CONTROVERSIE TRA TE E TRUCKOUT. CONTIENE UNA CLAUSOLA OBBLIGATORIA E FINALE DI ARBITRATO E UNA RINUNCIA ALLE AZIONI COLLETTIVE (SEZIONE 13). SI PREGA DI LEGGERE ATTENTAMENTE POICHÉ INFLUISCE SUI TUOI DIRITTI LEGALI, INCLUSO, SE APPLICABILE, IL TUO DIRITTO A NON ADERIRE ALL'ARBITRATO.
</p>
<h2>1. I nostri Servizi</h2>
TruckOut offre una piattaforma software-as-a-service che fornisce software per gestire e automatizzare il lavoro amministrativo relativo al trasporto conto terzi, inclusi la pianificazione, la gestione dei conducenti, la gestione dei documenti e la fatturazione.

<h2>2. Account, Password, Sicurezza e Uso del Telefono Mobile</h2>
Devi registrarti su TruckOut e creare un account per utilizzare i Servizi (un "Account"). Durante tale processo ti verrà richiesto di fornire alcune informazioni, inclusi, a titolo esemplificativo, il tuo nome, regione sociale/partita iva, numero di telefono e indirizzo e-mail. Utilizzando i Servizi, accetti di fornire informazioni veritiere, accurate, attuali e complete durante la procedura di registrazione e di mantenere e aggiornare tempestivamente le informazioni dell'Account per mantenerle accurate, attuali e complete.
Tu sei l'unico utente autorizzato del tuo Account. Sei responsabile della riservatezza di qualsiasi login, password e numero di Account fornitoti o assegnatoti da TruckOut per accedere ai Servizi. Sei interamente e completamente responsabile di tutte le attività che avvengono sotto la tua password o Account, anche se non autorizzate da te. TruckOut non ha alcun controllo sull'uso dell'Account di alcun utente e declina espressamente qualsiasi responsabilità derivante da ciò. In caso di sospetto che una parte non autorizzata stia utilizzando la tua password o Account, o sospetti di qualsiasi altra violazione della sicurezza, accetti di contattare immediatamente TruckOut.

<h2>3. Fatturazione e Pagamento</h2>
I pagamenti e qualsiasi altra spesa devono essere effettuati tramite il sistema di elaborazione dei pagamenti di terze parti indicato nei Servizi. 
Tutti i prezzi e le tariffe visualizzati nei Servizi sono esclusivi delle tasse applicabili federali, regionali, locali o di altro tipo (tasse applicabili). Le tasse applicabili si basano sulle tariffe applicabili all'indirizzo di fatturazione che ci fornisci e verranno calcolate al momento della transazione addebitata al tuo Account. Salvo diversamente indicato, tutti i prezzi, le tariffe e altri addebiti sono in valuta europea e tutti i pagamenti devono essere effettuati in tale valuta.

<h2>4. Contenuti Generati dagli Utenti</h2>
Il “Contenuto Generato dagli Utenti” è definito come qualsiasi contenuto, informazione e materiali che possono essere testuali, audio o visivi che fornisci, invii, carichi, pubblichi o rendi altrimenti disponibili ai Servizi e ai nostri utenti. Sei l'unico responsabile del Contenuto Generato dagli Utenti, e noi agiamo semplicemente come un condotto passivo per la tua distribuzione e pubblicazione online del tuo Contenuto Generato dagli Utenti.
Dichiari e garantisci a TruckOut che il tuo Contenuto Generato dagli Utenti non violerà alcuna legge, non sarà diffamatorio o osceno e non conterrà alcun virus o altri software dannosi.

<h2>5. Dichiarazioni e Garanzie</h2>
Dichiari e garantisci che: (i) hai almeno 18 anni o l'età legale nel tuo paese di residenza, e (ii) hai il diritto, l'autorità e la capacità di accettare questo Accordo. Inoltre, dichiari di rispettare le leggi locali e agire in buona fede.

<h2>6. Cessazione e Sospensione</h2>
Salvo diverso accordo scritto tra te e TruckOut, entrambe le parti possono risolvere questi Termini e Condizioni in qualsiasi momento senza causa. TruckOut si riserva il diritto di rifiutare i Servizi a chiunque in qualsiasi momento.

<h2>7. Link a Siti di Terze Parti</h2>
I Servizi possono contenere collegamenti a siti web di terze parti. Tali collegamenti non costituiscono un'approvazione da parte di TruckOut o un'associazione con tali siti. TruckOut non controlla né è responsabile per la disponibilità o accuratezza di tali siti.

<h2>8. Diritti di Proprietà Intellettuale</h2>
Tutti i contenuti dei Servizi, escluso il Contenuto Generato dagli Utenti, sono di proprietà di TruckOut. Non puoi copiare, scaricare o utilizzare alcun contenuto dei Servizi senza il consenso scritto di TruckOut.
i marchi e loghi di TruckOut sono protetti dalle leggi internazionali e italiane sui marchi.

<h2>9. Reclami sul Copyright</h2>
Se ritieni in buona fede che un materiale fornito tramite i Servizi violi il tuo copyright, contatta TruckOut all'indirizzo hello@trucksout.app.

<h2>10. Informazioni Riservate</h2>
Riconosci che le Informazioni Riservate di TruckOut sono preziose e ti impegni a non divulgarle. Le Informazioni Riservate includono segreti commerciali, informazioni aziendali e altri dati non pubblici.

<h2>11. Dichiarazione di Non Responsabilità</h2>
I SERVIZI VENGONO FORNITI “COSÌ COME SONO” SENZA GARANZIE DI ALCUN TIPO. TRUCKOUT NON SARÀ RESPONSABILE PER DANNI DERIVANTI DALL'USO DEI SERVIZI.

<h2>12. Indennizzo</h2>
Accetti di indennizzare e manlevare TruckOut da qualsiasi responsabilità derivante dall'uso improprio dei Servizi.

<h2>13. Risoluzione delle Controversie – Arbitrato e Rinuncia alle Azioni Collettive</h2>
Accetti che qualsiasi controversia relativa ai Servizi sarà risolta tramite arbitrato vincolante. TruckOut e tu rinunciate al diritto a un processo e alla partecipazione a qualsiasi azione collettiva.

<h2>14. Legge Applicabile</h2>
Salvo quanto previsto nella Sezione 13, questo Accordo sarà disciplinato dalle leggi italiane.

<h2>15. Nessuna Agenzia; Nessun Rapporto di Lavoro</h2>
Nessun rapporto di agenzia, partnership, joint venture o datore di lavoro-dipendente è creato da questo Accordo.

<h2>16. Disposizioni Generali</h2>
La mancata applicazione da parte di TruckOut di qualsiasi disposizione del presente Accordo non costituirà una rinuncia a tale disposizione.

                </div>
            </div>
    }
}

export default Terms;